import React from 'react';
import './../App.css';
import Header from './../components/Header/Header';
import Footer from './../components/Footer/Footer';
import TheCinemaSection from './../components/TheCinema/TheCinemaSection'






function TheCinema() { 
  return (
    <>
    <Header/>
    <TheCinemaSection/>
    <Footer/>
    </>
  );
}

export default TheCinema;
