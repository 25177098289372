import React from 'react';
import './../App.css';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import StakeYourAssetsSection from '../components/StakeYourAssetsSection/StakeYourAssetsSection'






function StakeYourAssets() { 
  return (
    <>
    <Header/>
    <StakeYourAssetsSection/>
    <Footer/>
    </>
  );
}

export default StakeYourAssets;
