import React from 'react';
import './../App.css';
import Header from './../components/Header/Header';
import Footer from './../components/Footer/Footer';
import Minting  from './../components/Minting/Minting'






function ComingSoon() { 
  return (
    <>
    <Header/>
    <Minting />
    <Footer/>
    </>
  );
}

export default ComingSoon;
