import React from "react";
import './VisitTheSet.css';
import { useAppSelector } from '../../redux/store'

function VisitTheSet(){
    const { web3, nft } = useAppSelector((state) => state.web3Connect)
    return(
        <>
        <section className="VisitTheSetSection">
            <div className="container">
                <div className="VisitTheSetHeading">
                    <a href={"https://streams.vagon.io/streams/ff912074-8154-4dfb-80f1-321da1afa086"} target="_blank" rel="noreferrer" className="siteButton extraWidthBtn" >VISIT AS A GUEST</a>
                    {web3 != "" && web3 != null && web3 != undefined ?
                        <>
                            <a href={"https://streams.vagon.io/streams/a051af96-3664-4f18-a2b0-e3231ccfd651"} target="_blank" rel="noreferrer" className="siteButton extraWidthBtn">Visit as an Executive Producer Pass Holder</a>
                            <a href={"https://drive.google.com/drive/folders/1CaI2ogd2nN_82ZoXgbOo2KHS8baHgokg"} target="_blank" rel="noreferrer" className="siteButton extraWidthBtn">EP PASS holder download for unlimited use on PC</a>
                        </>
                    :
                        <>
                                <a href={"Javascript:;"} className="siteButton extraWidthBtn disabledBtn">Visit as an Executive Producer Pass Holder</a>
                                <a href={"Javascript:;"} className="siteButton extraWidthBtn disabledBtn">EP PASS holder download for unlimited use on PC</a>
                        </>
                    }
                    <p className="visitSetInfoPage">The Set is not yet optimized for mobile. Visit on a desk or laptop for best experience</p>
                </div>
            </div>
        </section>
        </>
    );
}
export default VisitTheSet;