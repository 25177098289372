import React from "react";
import { Icon } from '@iconify/react';
import logo from '../../assets/imgs/siteLogo.png';
import './Footer.css';
import OpenSeaIcon from '../../assets/imgs/Opensea.png';
import { Link } from 'react-router-dom';


function Footer(){
    return(
        <>
        <footer className="footer">
            <div className="container">
                <div className="footerCols">
                    <div className="footerAbout">
                        <div className="logoWrap">
                            <Link to='/'><img src={logo} alt="Flinch"/></Link>
                            <p>Our mission is to revolutionize cinema. How? Replace the movie studio with a community that has ownership over a cinematic franchise via the blockchain. The Flinch Experience is a cinematic world filled with characters that are owned by the community. This community of creators and storytellers build the franchise together on the Ethereum network.</p>
                        </div>
                    </div>
                    <div className="footerPages">
                        <h3>Pages</h3>
                        <Link to="/thecinema" className="socialConnect">Watch The movie</Link>
                        <Link to="/assets" className="socialConnect">Collect Assets</Link>
                        <Link to="/stakeyourassets" className="socialConnect">Stake Your Assets.</Link>
                        <Link to="/visittheset" className="socialConnect">Visit Set</Link>
                    </div>
                    <div className="footerSocial">
                        <h3>Social Links</h3>
                        <div className="socialList">
                            {/* <a href={'https://www.flinchthemovie.com/whitepaper'} className="socialConnect" target={'_blank'} rel="noreferrer">
                                <Icon icon="raphael:paper" />
                            </a> */}
                            <a href={'https://opensea.io/collection/flinch-characters'} className="socialConnect" target={'_blank'} rel="noreferrer">
                                <img src={OpenSeaIcon} alt="OpenSea"/>
                                <span className='titleSocial'>1</span>
                            </a>
                            <a href={'https://twitter.com/FlinchEXP'} className="socialConnect" target={'_blank'} rel="noreferrer">
                                <Icon icon="simple-icons:twitter" />
                            </a>
                            <a href={'https://discord.gg/dQY2CPv9E4'} className="socialConnect" target={'_blank'} rel="noreferrer">
                                <Icon icon="simple-icons:discord" />
                            </a>
                            <a href={'https://opensea.io/collection/flinchep'} className="socialConnect" target={'_blank'} rel="noreferrer">
                                <img src={OpenSeaIcon} alt="OpenSea"/>
                                <span className='titleSocial'>2</span>
                            </a>
                            <a href={'https://www.instagram.com/flinchmovie/'} className="socialConnect" target={'_blank'} rel="noreferrer">
                                <Icon icon="ri:instagram-fill" />
                            </a>
                            <a href={'https://www.youtube.com/@ardorpictures'} className="socialConnect" target={'_blank'} rel="noreferrer">
                                <Icon icon="mdi:youtube" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <section className="copyrightBar">
            <div className="container">
                <p>Copyright 2023 © Design & Development by <a href={'https://geeksroot.com'} target="_blank" rel="noreferrer">Geeks Root</a>. All right reserved</p>
            </div>
        </section>
        </>
    );
}
export default Footer;