import React, {useState, useEffect} from "react";
import './TheCinema.css';
import ReactPlayer from "react-player"
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store'

function TheCinemaSection(){
    const dispatch = useAppDispatch()
    const { web3, nft } = useAppSelector((state) => state.web3Connect)
    return(
        <>
        {web3 && web3 != "" ?
            <section className="TheCinemaSection">
                <ReactPlayer
                    url="https://vimeo.com/461525907"
                    playing={true}
                    controls={true}
                    loop={true}
                    playsinline={true}
                    width="100%"
                    height="1080px"
                    className="vimeoEmbed"
                />
            </section>
            :
            <section className="TheCinemaSection">
                <ReactPlayer
                    url="https://vimeo.com/775690123/b84a24859e"
                    playing={true}
                    controls={true}
                    loop={true}
                    playsinline={true}
                    width="100%"
                    height="1080px"
                    className="vimeoEmbed"
                />
            </section>
        }
        <section className="featureFilm cinemaContent">
            <div className="container">
                <div className="featureFilmContent">
                    <div className="featureFilmHeader">
                        <h2>If you hold Flinch assets in your wallet you can watch the feature film by connecting your wallet.</h2>
                    </div>
                    <div className="filmMintArea">
                        <div className="notice">If you don't hold any Flinch assets yet, you can get them, here</div>
                        <Link to="/assets" className="siteButton mintButton">go to assets</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default TheCinemaSection;