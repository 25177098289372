import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import VisitTheSetPage from './Pages/VisitTheSet';
import TheCinema from './Pages/TheCinemaPage';
import Minting from './Pages/MintAsset';
import StakeYourAssets from './Pages/StakeYourAssets';




function App(){
  return (<>
  <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/visittheset" element={<VisitTheSetPage />} />
      <Route path="/assets" element={<Minting />} />
      <Route path="/thecinema" element={<TheCinema />} />
      <Route path="/stakeyourassets" element={<StakeYourAssets />} />
  </Routes>
  </>)
}
export default App;
