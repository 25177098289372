import React from 'react';
import './../App.css';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import VisitTheSet from './../components/VisitTheSet/VisitTheSet'






function VisitTheSetPage() { 
  return (
    <>
    <Header/>
    <VisitTheSet/>
    <Footer/>
    </>
  );
}

export default VisitTheSetPage;
