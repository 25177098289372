// import React from "react";
import React, { useState, useEffect}  from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { loadBlockchain,disconnectFromBlockchain, loadWalletConnect } from '../../redux/slices/web3ConnectSlice'

import { Icon } from '@iconify/react';
import logo from '../../assets/imgs/siteLogo.png';
import './Header.css';
import { Link } from 'react-router-dom';
import opensea from '../../assets/imgs/Opensea.png'





function Header(){

    const [showMenu, setShowMenu] = useState(false)
    const dispatch = useAppDispatch()
    const { web3, contract, web3LoadingErrorMessage } = useAppSelector((state) => state.web3Connect)

    // auto connect metamask
    useEffect(() => {
        handleMetamask();
      }, []);

    const handleMetamask = () => {
        dispatch(loadBlockchain());
        if(web3LoadingErrorMessage){
            alert(web3LoadingErrorMessage);
        }
    }

    const unloadBlockchain = () => {
        // disconnectFromBlockchain();
        // dispatch(disconnectFromBlockchain());

    }


    const handleWalletConnect = () => {
        dispatch(loadWalletConnect());
    }
    
    return(
        <>
        <header className={`header ${showMenu ? "menuActiveOverlay" : "MenuInactiveOverlay"}`}>
            <section className="siteHeader">
                <div className="container">
                    <div className="headerWrap">
                        <div className="navWrap">
                            <button onClick={ () => setShowMenu(!showMenu)}>
                                <Icon icon="line-md:menu-unfold-right" />
                            </button>
                            <div className="siteNavigation">
                            </div>
                        </div>
                        <div className="logoWrap">
                            <Link to='/'><img src={logo} alt="Flinch"/></Link>
                        </div>
                        {
                            web3 ?
                                <>
                                    <div className="ctaWrap">
                                        <button className="siteButton" onClick={() => unloadBlockchain()}>
                                            Wallet Connected
                                        </button>
                                    </div>
                                </>
                            :
                                <>
                                    <div className="ctaWrap">
                                        <button className="siteButton" onClick={() => handleMetamask()}>
                                            Connect Wallet
                                        </button>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </section>
            <section className="siteSocial">
                <div className="container">
                    <div className="socialList">
                        {/* <a href={'https://www.flinchthemovie.com/whitepaper'} className="socialConnect" target={'_blank'} rel="noreferrer">
                            <Icon icon="raphael:paper" />
                        </a> */}
                        <a href={'https://opensea.io/collection/flinch-characters'} className="socialConnect" target={'_blank'} rel="noreferrer">
                            <img src={opensea} alt="OpenSea"/>
                            <span className='titleSocial'>1</span>
                        </a>
                        <a href={'https://opensea.io/collection/flinchep'} className="socialConnect" target={'_blank'} rel="noreferrer">
                            <img src={opensea} alt="OpenSea"/>
                            <span className='titleSocial'>2</span>
                        </a>
                        <a href={'https://twitter.com/FlinchExp'} className="socialConnect" target={'_blank'} rel="noreferrer">
                            <Icon icon="simple-icons:twitter" />
                        </a>
                        <a href={'https://discord.gg/dQY2CPv9E4'} className="socialConnect" target={'_blank'} rel="noreferrer">
                            <Icon icon="simple-icons:discord" />
                        </a>
                        <a href={'https://www.instagram.com/flinchmovie/'} className="socialConnect" target={'_blank'} rel="noreferrer">
                            <Icon icon="ri:instagram-fill" />
                        </a>
                        <a href={'https://www.youtube.com/@ardorpictures'} className="socialConnect" target={'_blank'} rel="noreferrer">
                            <Icon icon="mdi:youtube" />
                        </a>
                    </div>
                </div>
            </section>
        </header>
        <div className={`slideMenu ${showMenu ? "menuActive" : "menuInActive"}`}>
            <div className='navWrap'>
                <button onClick={ () => setShowMenu(!showMenu)}>
                    <Icon icon="charm:cross"/>
                </button>
                <ul className='SideNavigation'>
                    <li><Link to="/thecinema" className="socialConnect">Watch The movie</Link></li>
                    <li><Link to="/assets" className="socialConnect">Collect Assets</Link></li>
                    <li><Link to="/stakeyourassets" className="socialConnect">Stake Your Assets</Link></li>
                    <li><Link to="/visittheset" className="socialConnect">Visit Set</Link></li>
                </ul>
                <div className="socialList">
                    {/* <a href={'https://www.flinchthemovie.com/whitepaper'} className="socialConnect" target={'_blank'} rel="noreferrer">
                        <Icon icon="raphael:paper" />
                    </a> */}
                    <a href={'https://opensea.io/collection/flinchnft'} className="socialConnect" target={'_blank'} rel="noreferrer">
                        <img src={opensea} alt="OpenSea"/>
                        <span className='titleSocial'>1</span>
                    </a>
                    <a href={'https://opensea.io/collection/flinchep'} className="socialConnect" target={'_blank'} rel="noreferrer">
                        <img src={opensea} alt="OpenSea"/>
                        <span className='titleSocial'>2</span>
                    </a>
                    <a href={'https://twitter.com/FlinchExp'} className="socialConnect" target={'_blank'} rel="noreferrer">
                        <Icon icon="simple-icons:twitter" />
                    </a>
                    <a href={'https://discord.gg/dQY2CPv9E4'} className="socialConnect" target={'_blank'} rel="noreferrer">
                        <Icon icon="simple-icons:discord" />
                    </a>
                    <a href={'https://www.instagram.com/flinchmovie/'} className="socialConnect" target={'_blank'} rel="noreferrer">
                        <Icon icon="ri:instagram-fill" />
                    </a>
                    <a href={'https://www.youtube.com/@ardorpictures'} className="socialConnect" target={'_blank'} rel="noreferrer">
                        <Icon icon="mdi:youtube" />
                    </a>
                </div>
            </div>
        </div>
        </>
    );
}
export default Header;