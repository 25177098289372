import React from "react";
import './StakeYourAssetsSection.css';


function StakeYourAssetsSection(){
    return(
        <>
        <section className="comingSoonSection">
            <div className="container">
                <div className="comingHeading">
                    <h2>Coming<br/>Soon</h2>
                </div>
            </div>
        </section>
        </>
    );
}
export default StakeYourAssetsSection;